import React from 'react';
import { Alert, Button, LinearProgress, LinearProgressProps } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { messagingActions } from '../../reducers/messagingSlice';
import { getOrderErrorMessage } from '../../utils/errors';
import { CartItem } from '../../utils/cart';
import { RootState } from '../../app/store';

interface IOrderProgressBar {
  status?: string;
  cartItem: CartItem;
}

export default function OrderProgressBar({ status, cartItem }: IOrderProgressBar) {
  const dispatch = useDispatch();
  const transactionState = useSelector((state: RootState) => {
    const orderState = state.order;
    if (orderState.currentTransactionId) {
      return orderState.transactions[orderState.currentTransactionId];
    }
    return null;
  });

  let progress = 0;
  let color: LinearProgressProps['color'] = undefined;
  switch (status) {
    case 'SENDING':
      color = undefined;
      progress = 33;
      break;
    case 'PENDING':
      color = undefined;
      progress = 66;
      break;
    case 'COMPLETED':
      color = 'success';
      progress = 100;
      break;
    case 'FAILED':
      color = 'error';
      progress = 100;
      break;
    default:
      color = undefined;
      progress = 0;
  }

  const sendMessage = (message: string) => {
    const payload = {
      data: { payload: message, status: 'final' },
    };
    dispatch(messagingActions.sendMessage(payload as any));
  };

  if (status === 'FAILED') {
    const errorMessage = (transactionState?.data?.result.data as any)?.display || 'An Unkown Error Has Occurred';
    const messageToCustomer = getOrderErrorMessage(errorMessage, cartItem);
    return (
      <Button
        onClick={() => sendMessage(messageToCustomer)}
        sx={{
          display: 'block',
          padding: '0px',
          width: '100%',
        }}
      >
        <Alert
          variant="filled"
          severity="error"
          sx={{
            '&:hover': {
              backgroundColor: '#FF3D74',
            },
          }}
        >
          {errorMessage}
        </Alert>
      </Button>
    );
  }

  return <LinearProgress variant="determinate" color={color} value={progress} />;
}
