import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

interface Props {
  open: boolean;
  title?: string;
  content: string | React.ReactNode;
  onClose: () => void;
  onConfirm?: () => void;
}

export default function ConfirmDialog({ open, title, content, onClose, onConfirm }: Props) {
  const handleClose = (_event: React.MouseEvent, reason: string) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (onConfirm) {
        onConfirm();
      }
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth onKeyDown={handleKeyDown}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined">
          Close
        </Button>
        {onConfirm && (
          <Button onClick={onConfirm} variant="contained">
            Confirm
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
