import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { memo, useEffect } from 'react';
import { useAlert } from 'react-alert';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector, useShallowSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import { CartItemReview } from '../../components/OrderReview/CartItemReview';
import CouponItemReview from '../../components/OrderReview/CouponItemReview';
import { orderActions, sendOrder } from '../../reducers/orderSlice';
import { isValidCartSelector } from '../../utils/cart';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    paddingBottom: '110px',
  },
}));

function OrderReview() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { cartItems, cartItemsQuantity, couponItem } = useShallowSelector((state) => state.cart);
  const cartValid = useSelector(isValidCartSelector);

  useEffect(() => {
    if (Object.values(cartItems).length > 0) {
      dispatch(sendOrder({ cartItems, cartValid }));
    }
  }, [cartItems, cartItemsQuantity, cartValid]);

  useEffect(() => {
    if (Object.values(cartItems).length > 0 && couponItem && !couponItem.isApplied) {
      dispatch(sendOrder({ cartItems, cartValid }));
    }
  }, [couponItem]);

  const cartItemsState = useAppSelector((state) => state.order.currentTransactionItems);
  const orderError = useAppSelector((state: RootState) => state.order.orderError);
  const alert = useAlert();

  useEffect(() => {
    if (orderError) {
      alert.error(orderError, { timeout: 5000 });
      dispatch(orderActions.setOrderError(''));
    }
  }, [orderError, alert, dispatch]);

  return (
    <div id="orderReview" className={classes.container}>
      {Object.values(cartItems).map((cartItem) => {
        return <CartItemReview key={cartItem.cartItemId} status={cartItemsState[cartItem.cartItemId]} cartItem={cartItem} quantity={cartItemsQuantity[cartItem.cartItemId]} />;
      })}
      {/* Display Coupon Item */}
      {couponItem && <CouponItemReview item={couponItem} />}
    </div>
  );
}

export default memo(OrderReview);
