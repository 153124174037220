import { AudioFrameTransmissionMessage } from '../reducers/messagingSlice';

type ProcessedAudioEvent = Omit<AudioFrameTransmissionMessage, 'data'> & {
  data: { payload: number[] };
};

export class PcmAudioPlayer {
  audioContext!: AudioContext;
  lastFlush: Number | undefined;
  workerNode!: AudioWorkletNode;

  constructor() {}

  async sendAudioEvents(lastFlush: number, events: AudioFrameTransmissionMessage[]) {
    if (lastFlush !== this.lastFlush) {
      this.lastFlush = lastFlush;
      try {
        const worker = await this.workerInitialize();
        worker.port.postMessage(events.map(this.preprocessEvent));
      } catch (e) {
        console.log(e);
      }
    }
  }

  async workerInitialize(sampleRate: number = 16000) {
    if (this.workerNode) {
      return this.workerNode;
    }
    if (!this.audioContext) {
      this.audioContext = new AudioContext({ sampleRate });
    }

    if (this.audioContext.state === 'suspended') {
      this.audioContext.resume();
    }
    if (this.audioContext.state !== 'running') {
      throw new Error('audioContext not in running state');
    }
    if (!this.workerNode) {
      await this.audioContext.audioWorklet.addModule('audio_worklet/worker-gadget.js');
      console.log('Creating worker');
      this.workerNode = new AudioWorkletNode(this.audioContext, 'events-audio-processor');
      this.workerNode.connect(this.audioContext.destination);
      console.log('Worker created: ', this.workerNode);
    }
    return this.workerNode;
  }

  pauseAudio() {
    this.audioContext?.suspend();
  }

  resumeAudio() {
    this.audioContext?.resume();
  }

  preprocessEvent(event: AudioFrameTransmissionMessage) {
    const processedEvent: ProcessedAudioEvent = {
      ...event,
      data: {
        ...event.data,
        payload: [],
      },
    };
    processedEvent.data.payload = Array.from(atob(event.data.payload)).map((c) => c.charCodeAt(0));
    return processedEvent;
  }
}
