enum TransitionTypes {
  slideOut = 'slide-out',
  fadeOut = 'fade-out',
}

enum Symbols {
  plus = '+',
  minus = '-',
  star = '*',
}

enum MenuStages {
  PLAYGROUND = 'PLAYGROUND',
  PRELIVE = 'PRELIVE',
  LIVE = 'LIVE',
}

export { TransitionTypes, Symbols, MenuStages };
