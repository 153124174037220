import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { AlertProviderProps, Provider as AlertProvider } from 'react-alert';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from '../src/features/auth/Login';
import App from './App';
import { store } from './app/store';
import AlertTemplate from './components/common/AlertTemplate';
import ErrorBoundaryProvider from './components/ErrorBoundary';
import './index.css';
import theme from './utils/theme';

const options: AlertProviderProps = {
  template: AlertTemplate,
  position: 'top center',
  offset: '130px',
  transition: 'scale',
};

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <ErrorBoundaryProvider>
          <AlertProvider {...options}>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<App />} />
                <Route path="login" element={<Login />} />
              </Routes>
            </BrowserRouter>
          </AlertProvider>
        </ErrorBoundaryProvider>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

if (window.hasOwnProperty('Cypress')) {
  (window as any).store = store;
}
