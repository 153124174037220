import React from 'react';
import { CartItem, getCartModGroupInChildModifierGroups } from '../../../utils/cart';
import { checkForUnavailableRequiredModifierGroup, isItem86edToday, ParsedMenuItem, ParsedModifierGroup } from '../../../utils/menu';
import MultipleSelectionModGroup from './MultipleSelectionModGroup';
import SingularSelectionModGroup from './SingularSelectionModGroup';

interface OwnProps {
  modGroup: ParsedModifierGroup;
  cartItem: CartItem;
  selectMenuItemModifier: (menuItem: ParsedMenuItem, modGroup: ParsedModifierGroup, selected: boolean) => void;
}

export interface IModGroupStyleProps {
  is86edToday?: boolean;
}

export default function ModGroup(props: OwnProps) {
  const { modGroup, cartItem, selectMenuItemModifier } = props;
  const allModifierUnavailable = !Object.values(modGroup.menuItems).find((menuItem) => {
    if ((menuItem.available || isItem86edToday(menuItem)) && !checkForUnavailableRequiredModifierGroup(menuItem.modifierGroups)) {
      return menuItem;
    }
  });

  const cartModGroup = getCartModGroupInChildModifierGroups(modGroup, cartItem);

  // handle default modifier
  if (!cartModGroup) {
    modGroup.defaultSelectedItemIds?.forEach((itemId) => {
      selectMenuItemModifier(modGroup.menuItems[itemId], modGroup, true);
    });
  }

  const isSingleChoice = modGroup.minimumSelections === modGroup.maximumSelections && modGroup.maximumSelections === 1;

  const handleModChange = (innerModGroup: ParsedModifierGroup) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const itemId = event.target.value;
    const isChecked = isSingleChoice || event.target.checked;
    if (itemId.length > 0) {
      selectMenuItemModifier(innerModGroup.menuItems[itemId], innerModGroup, isChecked);
    }
  };

  const modGroupProps = {
    cartItem,
    key: `${modGroup.id}_main_node`,
    modGroup,
    cartModGroup,
    onChange: handleModChange,
    selectMenuItemModifier,
  };

  if (allModifierUnavailable) return <></>;
  if (isSingleChoice) return <SingularSelectionModGroup {...modGroupProps} />;
  return <MultipleSelectionModGroup {...modGroupProps} />;
}
