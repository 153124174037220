import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import Colors from './color';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: Colors.daintree,
    },
    secondary: {
      main: Colors.gold,
    },
    error: {
      main: Colors.torchRed,
    },
    success: {
      main: Colors.abbey,
    },
  },
  typography: {
    fontFamily: ["'Prompt'", 'Helvetica', 'Arial', 'sans-serif'].join(','),
  },
});

export default theme;
