import { IconButton, Theme } from '@mui/material';
import React from 'react';
import Colors from '../../utils/color';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  iconButtonClass: {
    width: '40px',
    height: '100%',
    padding: '0px !important',
    display: 'flex',
    flexDirection: 'column',
    margin: '4px !important',
  },
  iconComponentClass: {
    color: (props: IVisualIndicator) => (props.customizedColor ? props.customizedColor : props.isActive ? Colors.activeIndicator : Colors.dim),
    width: '100% !important',
    height: (props: any) => (props.isActive ? '50px !important' : '40px !important'),
    padding: (props: any) => (props.isActive ? '4px' : '10px'),
    borderRadius: '4px',
  },
}));

interface IVisualIndicator {
  IconComponent: React.ElementType;
  onClick?: () => void;
  isActive?: boolean;
  customizedColor?: string;
}

const VisualIndicator = (props: IVisualIndicator) => {
  const { iconButtonClass, iconComponentClass } = useStyles(props);
  const { onClick, IconComponent } = props;

  return (
    <IconButton className={iconButtonClass} onClick={onClick}>
      <IconComponent fontSize="small" className={iconComponentClass} />
    </IconButton>
  );
};

export default VisualIndicator;
