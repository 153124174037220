import { ErrorResponse } from './types';
import { CartItem } from './cart';

export const getErrorMessages = (errorResponse: ErrorResponse) => {
  if (Array.isArray(errorResponse) && errorResponse.length === 1) {
    return errorResponse[0].message;
  }
  return errorResponse.map((response) => response.message);
};

export const getOrderErrorMessage = (error: string, cartItem: CartItem) => {
  let result = 'Sorry, ';
  if (error.includes(cartItem.name) || error == 'An Unkown Error Has Occurred') {
    result += cartItem.name;
  } else {
    Object.values(cartItem.childModifierGroups).forEach((childModGroup) => {
      Object.values(childModGroup.selectedItems).forEach((selectedItem) => {
        if (error.includes(selectedItem.name)) {
          result += selectedItem.name + ' ';
        }
      });
    });
    result += 'for ' + cartItem.name;
  }
  return (result += ' is not available right now. Would you like something else?');
};
