import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NodeEnv } from '../utils/types';

export interface ConfigState {
  NODE_ENV: NodeEnv;
  ITEM_BY_ITEM: boolean;
  DISABLE_INTENT_INTERVAL: number;
}

export const initialState: ConfigState = {
  NODE_ENV: process.env.REACT_APP_DEPLOY_ENV?.toUpperCase() || (process.env.NODE_ENV.toUpperCase() as any),
  ITEM_BY_ITEM: (process.env.REACT_APP_ITEM_BY_ITEM ? process.env.REACT_APP_ITEM_BY_ITEM : 'FALSE').toUpperCase() === 'TRUE',
  DISABLE_INTENT_INTERVAL: 3000, // in milisecond
};

const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Partial<ConfigState>>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const configActions = configSlice.actions;
export default configSlice.reducer;
