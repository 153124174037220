const FORCE_RELOAD_CHECK_TIME = 60_000;
const IDLE_TIME = 1000 * 60 * 60; // 60 MIN  IDEAL TIME OUT

const KEY = 'key';
const POS_ID = 'pos_id';
const DUMMY_ITEM = 'dummy_item';
const ID = 'id';
const MAX_CART_ITEM_QUANTITY = 19;

export { FORCE_RELOAD_CHECK_TIME, IDLE_TIME };
export { ID, KEY, POS_ID, DUMMY_ITEM, MAX_CART_ITEM_QUANTITY };

