const inMemoryGroupIdMapping = () => {
  let groupIdMapping: Record<string, any> = {};

  const getGroupIdMapping = () => groupIdMapping || {};

  const setGroupIdMapping = (updatedGroupMappping: Record<string, any>) => {
    groupIdMapping = updatedGroupMappping;
  };

  return {
    getGroupIdMapping,
    setGroupIdMapping,
  };
};

export default inMemoryGroupIdMapping();
