import { UserState } from '../reducers/userSlice';
import logger from './logger';
import { nullOrUndefined } from './types';

const USER_STATE_KEY = 'user-info';
const USER_STATE_VERSION_KEY = 'user-info-version';

const AUTH_TOKEN_KEY = 'auth-token';

const SESSION_ID = 'session-id';

export const saveAuthToken = (authToken: string | nullOrUndefined) => {
  if (authToken) {
    logger.debug('Saving auth token to local storage');
    localStorage.setItem(AUTH_TOKEN_KEY, authToken);
  } else {
    logger.debug('Removing auth token from local storage');
    localStorage.removeItem(AUTH_TOKEN_KEY);
  }
};

export const getAuthToken = (): string | undefined => {
  const authToken = localStorage.getItem(AUTH_TOKEN_KEY);
  if (authToken && authToken !== '') {
    logger.debug('Restoring auth token from local storage');
    return authToken;
  }
  logger.debug('No auth token found in local storage');
  return undefined;
};

// Change this versionNumber whenever the format for user state changes so we know to delete the old data
const userStateVersion = '1.0.0';
export const getUserState = (): UserState | undefined => {
  // If called on server, localStorage doesn't exist
  if (typeof window === 'undefined') {
    logger.debug('Not loading user state b/c "window" is not defined');
    return undefined;
  }
  const serializedState = localStorage.getItem(USER_STATE_KEY);
  if (serializedState === null) {
    logger.debug('No previous user state found');
    return undefined;
  }
  const foundUserStateVersion = localStorage.getItem(USER_STATE_VERSION_KEY);
  if (!foundUserStateVersion || foundUserStateVersion !== userStateVersion) {
    logger.debug('User state version is out of date. Disregarding');
    localStorage.removeItem(USER_STATE_KEY);
    return undefined;
  }
  const userState = JSON.parse(serializedState);
  if (userState) {
    logger.debug('Restoring previous user state');
    return userState;
  }
  logger.debug('User state is falsey');
  return undefined;
};

export const saveUserState = (userState: UserState) => {
  logger.debug('Saving user state to local storage');
  const serializedState = JSON.stringify(userState);
  localStorage.setItem(USER_STATE_KEY, serializedState);
  localStorage.setItem(USER_STATE_VERSION_KEY, userStateVersion);
};

export const saveSessionID = (sessionid: string | nullOrUndefined) => {
  if (sessionid) {
    logger.debug('Saving session id local storage');
    localStorage.setItem(SESSION_ID, sessionid);
  } else {
    logger.debug('Removing session id from local storage');
    localStorage.removeItem(SESSION_ID);
  }
};

export const getSessionID = (): string | null => {
  return localStorage.getItem(SESSION_ID);
};
