import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Card, CardContent, Theme, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { printCurrency } from '../../utils/money';
import { activeCartSelector, cartTotalSelector } from '../../utils/cart';
import Colors from '../../utils/color';
import { useAppSelector, useShallowSelector } from '../../app/hooks';
import { selectOrderValues } from '../../selectors/order';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    padding: theme.spacing(3.5, 2),
    backgroundColor: Colors.white,
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& .MuiTypography-root': {
        fontSize: '12px',
      },
    },
    '& div:last-child': {
      '& .MuiTypography-root': {
        fontSize: '20px',
        fontWeight: 'bold',
      },
    },
  },
}));

export default function OrderTotalContainer() {
  const classes = useStyles();
  const ITEM_BY_ITEM = useAppSelector((state) => state.config.ITEM_BY_ITEM);
  const { subtotal, tax, total } = useShallowSelector(selectOrderValues);
  const cartActive = useSelector(activeCartSelector);

  if (!cartActive) {
    return <></>;
  }

  const getPrice = (amount: number | null) => (ITEM_BY_ITEM && amount !== null ? printCurrency(amount, 'USD') : '--');
  const subtotalLine = getPrice(subtotal);
  const taxLine = getPrice(tax);
  const totalLine = getPrice(total);

  return (
    <div className={classes.container}>
      <div>
        <Typography>Subtotal:</Typography>
        <Typography>{subtotalLine}</Typography>
      </div>
      <div>
        <Typography>Tax:</Typography>
        <Typography>{taxLine}</Typography>
      </div>
      <div>
        <Typography>Total:</Typography>
        <Typography>{totalLine}</Typography>
      </div>
    </div>
  );
}
