import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { dialogActions } from '../../reducers/dialogSlice';
import { messagingActions, TextFrameTransmissionMessage } from '../../reducers/messagingSlice';
import Colors from '../../utils/color';
import { INTENT_CARDS } from '../../utils/constants';
import { restaurantInfoSelector } from '../../utils/restaurants';
import { SpeechToText } from '../Speech/SpeechToText';
import { chooseRandomString } from '../../utils/common';
import { AutocompleteComp } from './AutocompleteComp';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: '96px',
    overflow: 'hidden',
  },
  wrapForm: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: `${theme.spacing(2.5)} ${theme.spacing(3.5)}`,
    border: `${theme.spacing(0.125)} solid ${Colors.alto}`,
  },
}));

export const OmniBar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isStaffIntervention = useAppSelector((state) => state.messages.isStaffIntervention);
  const [currentMessage, setCurrentMessage] = useState('');
  const [inProgressMessage, setInProgressMessage] = useState<string | null>(null);
  const selectedRestaurant = useSelector(restaurantInfoSelector);

  const updateCurrentMessage = (message: string) => {
    setCurrentMessage(message);
  };

  const updateInProgressMessage = (inProgressMessage: string | null) => {
    setInProgressMessage(inProgressMessage);
  };

  const handleSpeechRecognition = (transcript: string, isFinal: boolean, ctrl: boolean) => {
    if (isFinal && transcript !== '') {
      let matched = INTENT_CARDS.filter((card) => card.title.toLowerCase() === transcript.toLowerCase());
      if (matched.length === 1) {
        if (matched[0].title === 'Read Back') {
          dispatch(dialogActions.setVoiceBoardValue(''));
          updateCurrentMessage('');
        } else {
          let prompt = chooseRandomString(matched[0].messages);
          sendMessage(prompt);
          dispatch(dialogActions.setVoiceBoardValue(''));
          updateCurrentMessage('');
        }
      } else {
        updateCurrentMessage('/' + transcript);
        dispatch(dialogActions.setVoiceBoardValue('/' + transcript));
      }
      updateInProgressMessage(null);
    } else {
      updateInProgressMessage('/' + transcript);
    }
  };

  const sendMessage = (message: string, metadata?: any, title?: string) => {
    message = message.replaceAll('{RESTAURANT_NAME}', selectedRestaurant?.restaurantName || '');
    const payload: Partial<TextFrameTransmissionMessage> = {
      data: { payload: message, status: 'final', metadata },
    };
    dispatch(messagingActions.sendMessage(payload as any));
  };

  return (
    <div className={classes.container} style={isStaffIntervention ? { backgroundColor: Colors.torchRed } : undefined}>
      <form className={classes.wrapForm} noValidate autoComplete="off">
        <SpeechToText onSpeechRecognition={handleSpeechRecognition} />
        <AutocompleteComp currentMessage={currentMessage} updateCurrentMessage={updateCurrentMessage} inProgressMessage={inProgressMessage} sendMessage={sendMessage} />
      </form>
    </div>
  );
};
