import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AICommands } from '../../constants/event';
import { resetHypothesisFrame, setIsAIActive } from '../../reducers/messagingSlice';
import { resetAI } from '../../redux/features/ai/ai.slice';
import { selectIsAIActive } from '../../selectors/message';
import { sendAIInfo } from '../../utils/hypotheses';

const useAIMessaging = () => {
  const dispatch = useAppDispatch();
  const isAIActive = useAppSelector(selectIsAIActive);
  const toggleAIIndicator = () => {
    const aIStatus = !isAIActive;
    dispatch(setIsAIActive(aIStatus));
    if (aIStatus) {
      dispatch(resetAI());
      dispatch(resetHypothesisFrame());
    }
    sendAIInfo({ aIStatus }, dispatch);
  };
  const applyAICommand = (aiCommand: AICommands) => {
    const aIStatus = aiCommand === AICommands.activate;
    dispatch(setIsAIActive(aIStatus));
    sendAIInfo({ aIStatus }, dispatch);
  };

  return {
    isAIActive,
    toggleAIIndicator,
    applyAICommand,
  };
};

export default useAIMessaging;
