import { FormControl, FormLabel, RadioGroup, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { CartItem, CartModifierGroup } from '../../../utils/cart';
import { isItem86edToday, ParsedMenuItem, ParsedModifierGroup } from '../../../utils/menu';
import { SingularModChoice } from '../components/ModChoice';
import { ModGroupLabel } from '../components/ModGroupLabel';
import { RecursiveModGroup } from '../components/RecursiveModGroup';
import { sortAndFilterMenuItems } from '../util/helper';

interface OwnProps {
  cartItem: CartItem;
  modGroup: ParsedModifierGroup;
  cartModGroup: CartModifierGroup | null;
  onChange: (modGroup: ParsedModifierGroup) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectMenuItemModifier: (menuItem: ParsedMenuItem, modGroup: ParsedModifierGroup, selected: boolean) => void;
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  modGroup: {
    margin: `${spacing(1)} 0px 0px 0px !important`,
    '& legend': {
      width: '100%',
    },
    padding: `0px ${spacing(1)}`,
    width: '100%',
  },
  choiceContainer: {
    marginTop: spacing(1.625),
  },
}));

export default function SingularSelectionModGroup(props: OwnProps) {
  const classes = useStyles({});
  const { modGroup, onChange, cartModGroup, cartItem } = props;
  let currentValue: string | null = null;
  let selectedModNumber = 0;
  if (cartModGroup) {
    const selectedMod = cartModGroup.selectedItems;
    currentValue = Object.values(selectedMod)[0]?.itemId || null;
    selectedModNumber = Object.values(selectedMod).length;
  } else if (cartItem.childModifierGroups[modGroup.id]) {
    const childModGroup = cartItem.childModifierGroups[modGroup.id];
    const selectedMod = childModGroup.selectedItems;
    currentValue = Object.values(selectedMod)[0]?.itemId || null;
    selectedModNumber = Object.values(selectedMod).length;
  }

  // sort and filter the modifiers before rendering
  const menuItems = sortAndFilterMenuItems(modGroup.menuItems);
  const key = [cartItem.id, modGroup.id].join('_');

  return (
    <React.Fragment key={key}>
      <FormControl component="fieldset" className={classes.modGroup}>
        <FormLabel component="legend">
          <ModGroupLabel modGroup={modGroup} selectedModNumber={selectedModNumber} />
        </FormLabel>
        <RadioGroup row aria-label={modGroup.name} name={modGroup.name} value={currentValue} onChange={onChange(modGroup)} className={classes.choiceContainer}>
          {menuItems.map((child) => {
            if (child.available || isItem86edToday(child)) {
              return <SingularModChoice key={key + child.id} item={child} currentValue={currentValue} />;
            }
            return null;
          })}
        </RadioGroup>
        {/* </Grid> */}
      </FormControl>
      <RecursiveModGroup {...props} />
    </React.Fragment>
  );
}
