import { Dispatch } from '@reduxjs/toolkit';
import { EventTypes } from '../constants/event';
import { InfoTransmissionMessage, messagingActions } from '../reducers/messagingSlice';
import { ParsedMenuItem, ParsedModifierGroup } from './menu';
import { GenericMap } from './types';

export const getModifierAndModGroupFromHypotheses = (topLevelMenuItem: ParsedMenuItem, modId: string) => {
  let modifier: ParsedMenuItem | undefined;
  const modGroups: GenericMap<ParsedModifierGroup> = topLevelMenuItem.modifierGroups;
  const modGroup = Object.values(modGroups).find((modGroup) => {
    modifier = Object.values(modGroup.menuItems).find((item) => item.id === modId) as ParsedMenuItem;
    return modifier;
  });
  return { modGroup, modifier };
};

export const sendAIInfo = (metadata: any, dispatch: Dispatch) => {
  const payload: Partial<InfoTransmissionMessage> = {
    data: { metadata, type: EventTypes.hypothesis, message: '' },
  };
  dispatch(messagingActions.sendInfo(payload as InfoTransmissionMessage));
};
