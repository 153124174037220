import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';
import { SuggestionStatus } from './ai.constants';

const selectAI = (state: RootState) => state.ai;
const selectSuggestionsById = createSelector(selectAI, (ai) => ai.suggestionsById);
const selectSuggestionsOrder = createSelector(selectAI, (ai) => ai.suggestionsOrder);
const selectSuggestions = createSelector(selectSuggestionsById, selectSuggestionsOrder, (suggestionsById, suggestionsOrder) => ({
  suggestionsById,
  filteredSuggestionsOrder: suggestionsOrder.filter((id) => suggestionsById[id].suggestionStatus === SuggestionStatus.new),
}));

export { selectSuggestions };
