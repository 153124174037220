import * as React from 'react';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { messagingActions } from '../../reducers/messagingSlice';
import { IconButton, Theme } from '@mui/material';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import Colors from '../../utils/color';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  iconContainer: {
    width: '40px',
    height: '100%',
    padding: '0px !important',
    display: 'flex',
    flexDirection: 'column',
    margin: '4px !important',
  },
  icon: {
    color: Colors.dim,
    width: '100% !important',
    height: '40px !important',
    padding: '8px',
    paddingRight: '4px',
    borderRadius: '4px',
  },
  active: {
    color: '#06bee1',
    width: '100% !important',
    height: '50px !important',
    padding: '2px',
    paddingRight: '4px',
    borderRadius: '4px',
  },
}));

export const Player = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isPlaying = useAppSelector((state) => state.messages.isPlaying);
  const player = useAppSelector((state) => state.messages.player);
  const bufferedAudioFrames = useAppSelector((state) => state.messages.audioBuffer.frames);
  const lastFlush = useAppSelector((state) => state.messages.audioBuffer.lastBufferFlush);
  if (isPlaying && bufferedAudioFrames.length) {
    player.sendAudioEvents(lastFlush, bufferedAudioFrames);
  }
  const toggleAudio = async () => {
    if (isPlaying) {
      dispatch(messagingActions.setIsPlaying(false));
    } else {
      dispatch(messagingActions.setIsPlaying(true));
      player.sendAudioEvents(lastFlush, bufferedAudioFrames);
    }
  };

  return (
    <IconButton onClick={() => toggleAudio()} className={classes.iconContainer}>
      {
        <>
          <VolumeUpIcon fontSize="small" className={isPlaying ? classes.active : classes.icon} />
        </>
      }
    </IconButton>
  );
};
