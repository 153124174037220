import { ModalityType } from '../../../generated-interfaces/graphql';
import { checkForUnavailableRequiredModifierGroup, getMenuItemPrice, ParsedMenuItem } from '../../../utils/menu';
import { printCurrency } from '../../../utils/money';
import { GenericMap } from '../../../utils/types';

const novowelWithoutStitute = (str: string) => (str.length > 28 ? str.replace(/[aeiou]/gi, '') : str.replace('stitute', ''));

export const getModGroupItemLabel = (item: ParsedMenuItem, modalityState: ModalityType) => {
  const price = getMenuItemPrice(item, modalityState);
  return `${novowelWithoutStitute(item.name)} ${price ? `(${printCurrency(price, 'USD')})` : ''}`;
};

export const sortAndFilterMenuItems = (menuItems: GenericMap<ParsedMenuItem>) => {
  return Object.values(menuItems)
    .sort((a, b) => {
      if (a.sortOrderByModifierGroup !== null && b.sortOrderByModifierGroup !== null) {
        return a.sortOrderByModifierGroup - b.sortOrderByModifierGroup;
      } else if (a.sortOrderByModifierGroup !== null) {
        return -1;
      } else if (b.sortOrderByModifierGroup !== null) {
        return 1;
      }
      return 0;
    })
    .filter((menuItem) => !checkForUnavailableRequiredModifierGroup(menuItem.modifierGroups));
};
