import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

const selectRestaurant = (state: RootState) => state.restaurant;
const selectActiveRestaurantCode = createSelector(selectRestaurant, (state) => state.selectedRestaurantCode);

export { selectActiveRestaurantCode };

export const selectTimezon = (state: RootState) => state.restaurant.selectedRestaurant?.hoursOfOperation?.timezone;
export const selectCurrentUTCTime = (state: RootState) => state.restaurant.selectedRestaurant?.hoursOfOperation?.currentUTCTime;
