import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Menu, MenuItem, TextField, Theme, Tooltip, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../../app/hooks';
import { Restaurant } from '../../generated-interfaces/graphql';
import { messagingActions } from '../../reducers/messagingSlice';
import { selectRestaurant } from '../../reducers/restaurantSlice';
import { forceLogOutUserSession, updateUserSession } from '../../reducers/userSlice';
import { selectNodeEnv } from '../../selectors/config';
import { SOURCE } from '../../utils/constants';
import { getAuthToken } from '../../utils/local-storage';
import { getForceLogOutApiUrl } from '../../utils/network';
import { restaurantInfoSelector, restaurantsByUserRoleSelector } from '../../utils/restaurants';
import ActiveSessionConfirmDialog from './ActiveSessionConfirmDialog';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    // color: '#E0E0E0',
    // borderColor: '#203F53',
    margin: theme.spacing(1, 2),
  },
  dropdownIcon: {
    color: '#e0e0e0',
  },
  menu: {
    '& .MuiMenu-paper': {
      width: '255px',
      maxHeight: '230px',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  input: {
    color: '#616161',
  },
  name: {
    margin: '0 auto',
    color: theme.palette.common.white,
  },
}));

export const RestaurantPicker = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const restaurantsByUserRole = useSelector(restaurantsByUserRoleSelector);
  const selectedRestaurant = useSelector(restaurantInfoSelector);
  const isPlaying = useAppSelector((state) => state.messages.isPlaying);
  const [restaurantsMap, setRestaurantsMap] = useState<Record<string, Restaurant>>({});
  const [search, setSearch] = useState<string>('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const filterRef = React.useRef<HTMLInputElement>();
  const env = useSelector(selectNodeEnv);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<{ restaurantCode: string; primaryRestaurantCode: string | null; data: [any] }>();
  const isMenuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setRestaurantsMap(
      restaurantsByUserRole.reduce((a, c) => {
        c.restaurants.forEach((r) => {
          if (!(r.restaurantCode in a)) {
            a[r.restaurantCode] = r;
          }
        });
        return a;
      }, {} as Record<string, Restaurant>)
    );
  }, [restaurantsByUserRole]);

  const restaurants = Object.values(restaurantsMap);
  const showSearchInput = restaurants.length > 5;
  const showDropdown = restaurants.length > 1;
  const filteredRestaurantsEntries =
    showSearchInput && search
      ? restaurants.filter((restaurant) => {
          return restaurant.restaurantName.toLocaleLowerCase().includes(search.toLocaleLowerCase());
        })
      : restaurants;

  if (!showDropdown) {
    return (
      <Typography className={classes.name} variant="subtitle1">
        {selectedRestaurant?.restaurantName}
      </Typography>
    );
  }

  const stopPropagation = (e: React.KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowUp':
      case 'Home':
      case 'End':
        break;
      default:
        e.stopPropagation();
    }
  };
  const moveFocusToInput = (e: React.KeyboardEvent<HTMLLIElement>) => {
    if (e.key === 'Tab' || e.key === 'ArrowRight') {
      e.stopPropagation();
      e.preventDefault();
      if (filterRef?.current) {
        filterRef.current.focus();
      }
    }
  };
  const handleRestaurantSelect = (restaurantCode: string, primaryRestaurantCode: string) => {
    fetchActiveSession(restaurantCode)?.then((response) => {
      if (response.status !== 200) {
        selectUpdatedRestaurant(restaurantCode, primaryRestaurantCode);
      } else {
        response.json().then((data) => {
          setDialogData({ restaurantCode, primaryRestaurantCode, data });
          setShowConfirmModal(true);
        });
      }
    });
  };

  const selectUpdatedRestaurant = (restaurantCode: string, primaryRestaurantCode: string) => {
    dispatch(updateUserSession(restaurantCode));
    dispatch(selectRestaurant({ restaurantCode: restaurantCode, primaryRestaurantCode: primaryRestaurantCode }));
    setSearch('');
    handleClose();

    // Reset state of cars
    dispatch(messagingActions.resetCarState());

    if (!isPlaying) {
      dispatch(messagingActions.setIsPlaying(true));
    }
  };

  const fetchActiveSession = (restaurantCode: string) => {
    const authToken = getAuthToken();
    if (!authToken) {
      return;
    }
    try {
      const url = getForceLogOutApiUrl(env);
      const result = fetch(`${url}?source_module=${SOURCE}&restaurant_code=${restaurantCode}&active=1&force_reload=0`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
      });
      return result;
    } catch (error) {
      console.error('Failed to fetching session', error);
    }
  };

  const onConfirm = (restaurantCode: string, primaryRestaurantCode: string) => {
    selectUpdatedRestaurant(restaurantCode, primaryRestaurantCode);
    setShowConfirmModal(false);
  };

  return (
    <>
      {dialogData && (
        <ActiveSessionConfirmDialog
          content={dialogData}
          open={showConfirmModal}
          onForceLogOut={(selectedSessions: any[]) => {
            if (dialogData.data) {
              dispatch(forceLogOutUserSession({ restarauntCode: dialogData.restaurantCode, activeSessions: selectedSessions }));
            }
            onConfirm(dialogData.restaurantCode, dialogData.primaryRestaurantCode as string);
          }}
          onConfirm={() => {
            onConfirm(dialogData.restaurantCode, dialogData.primaryRestaurantCode as string);
          }}
          onClose={() => setShowConfirmModal(false)}
        />
      )}
      <Button
        disableElevation
        aria-controls="restaurant-picker"
        aria-haspopup="true"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
        }}
        className={classes.button}
        color="secondary"
        variant="contained"
      >
        {selectedRestaurant?.restaurantName || 'Choose One'}
        <ArrowDropDownIcon />
      </Button>
      <Menu id="restaurant-picker" className={classes.menu} keepMounted anchorEl={anchorEl} open={isMenuOpen} onClose={handleClose} variant="menu">
        {showSearchInput && (
          <MenuItem onKeyDown={moveFocusToInput}>
            <TextField
              data-testid="restaurant-search"
              className={classes.input}
              inputRef={filterRef}
              value={search}
              onKeyDown={stopPropagation}
              onChange={(e) => {
                setSearch(e.currentTarget.value);
              }}
              InputProps={{
                startAdornment: <SearchIcon />,
              }}
            />
          </MenuItem>
        )}
        {filteredRestaurantsEntries.map((restaurant) => {
          return (
            <MenuItem
              key={restaurant.restaurantCode}
              data-testid="restaurant"
              onClick={() => {
                if (selectedRestaurant?.restaurantCode !== restaurant.restaurantCode) {
                  handleRestaurantSelect(restaurant.restaurantCode, restaurant.primaryRestaurantCode as string);
                }
              }}
            >
              <Tooltip title={restaurant.restaurantCode} arrow>
                <Typography>{restaurant.restaurantName}</Typography>
              </Tooltip>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
