import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ParsedMenuItem, TopLevelMenuItem } from '../utils/menu';

export interface DialogState {
  step: number;
  steps: string[];
  selectedItem: ParsedMenuItem | undefined;
  selectedItemCartId: number;
  invalidModGroupDescriptions: string[];
  voiceBoardValue: string;
  intentsStatus: boolean[];
}
const initialState: DialogState = {
  step: 0,
  selectedItem: undefined,
  selectedItemCartId: 0,
  invalidModGroupDescriptions: [],
  voiceBoardValue: '',
  steps: ['What can I get for you today?', 'Anything else?', 'Thanks, please pull up to the next window!'],
  intentsStatus: [],
};
const dialogSlice = createSlice({
  name: 'dialog',
  initialState: initialState,
  reducers: {
    updateSelectedItem: (state, action: PayloadAction<{ item: ParsedMenuItem; itemCartId: number } | undefined>) => {
      if (action.payload) {
        state.selectedItem = action.payload.item;
        state.selectedItemCartId = action.payload.itemCartId;
      } else {
        state.selectedItem = undefined;
        state.selectedItemCartId = 0;
      }
    },
    cloneSelectedItem: (state, action: PayloadAction<{ cartItemId: number }>) => {
      state.selectedItemCartId = action.payload.cartItemId;
    },
    addInvalidModGroupDescriptions: (state, action: PayloadAction<string[]>) => {
      state.invalidModGroupDescriptions.concat(action.payload);
    },
    increaseStep: (state) => {
      state.step += 1;
    },
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setVoiceBoardValue: (state, action: PayloadAction<string>) => {
      state.voiceBoardValue = action.payload;
    },
    initIntentsStatus: (state, action: PayloadAction<boolean[]>) => {
      state.intentsStatus = action.payload;
    },
    setIntentsStatus: (state, action: PayloadAction<{ idx: number; status: boolean }>) => {
      state.intentsStatus[action.payload.idx] = action.payload.status;
    },
  },
});

export const dialogActions = dialogSlice.actions;
export default dialogSlice.reducer;
