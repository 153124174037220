import React from 'react';
import { AppBar, Container, styled, Typography } from '@mui/material';
import { PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

const Wrapper = styled('div')(() => ({
  width: '100vw',
  height: '100vh',
  position: 'relative',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledLink = styled('a')(() => ({
  color: '#0672CB',
  textDecoration: 'none',
}));

function ErrorFallback() {
  return (
    <>
      <AppBar />
      <Wrapper>
        <Container maxWidth="lg">
          <img src="/presto_logo.svg" alt="Presto Logo" />
          <Typography variant="h4" gutterBottom>
            Something went wrong.
          </Typography>
          <Typography>
            {'Please contact '}
            <StyledLink href="mailto:support@presto.com">support</StyledLink>
          </Typography>
        </Container>
      </Wrapper>
    </>
  );
}

export default function ErrorBoundaryProvider({ children }: PropsWithChildren<{}>) {
  return <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>;
}
