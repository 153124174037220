import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

const selectOrder = (state: RootState) => state.order;

const selectOrderValues = createSelector(selectOrder, ({ subtotal, tax, total }) => ({
  subtotal: subtotal && subtotal.length ? parseInt((Number(subtotal) * 100).toFixed(0)) : null,
  tax: tax && tax.length ? parseInt((Number(tax) * 100).toFixed(0)) : null,
  total: total && total.length ? parseInt((Number(total || 0) * 100).toFixed(0)) : null,
}));

export { selectOrder, selectOrderValues };
