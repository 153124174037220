import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from '../app/store';
import { AuthStatusQuery, GroupIdMappingQuery, LoginQueryQuery, Query } from '../generated-interfaces/graphql';
import { resetAI } from '../redux/features/ai/ai.slice';
import { SOURCE } from '../utils/constants';
import inMemoryGroupIdMapping from '../utils/inMemoryGroupIdMapping';
import { getAuthToken, getSessionID, saveSessionID, saveUserState } from '../utils/local-storage';
import { getForceLogOutApiUrl, getGraphQLClient, setAuthToken } from '../utils/network';
import { ERROR_ACTIONS } from './errorSlice';
import { resetHypothesisFrame, setIsAIActive } from './messagingSlice';
import { restaurantsByUserRole } from './restaurantSlice';

export interface UserState {
  userProfile: LoginQueryQuery['login'] | null;
  isLoggedIn: boolean;
  token: string | null;
  didAttemptAuthCheck: boolean;
}

export const initialUserState: UserState = {
  userProfile: null,
  isLoggedIn: false,
  token: null,
  didAttemptAuthCheck: false,
};

export const login = createAsyncThunk('user/login', async ({ email, password }: { email: string; password: string }, thunkApi) => {
  const sdk = getGraphQLClient((thunkApi.getState() as RootState).config.NODE_ENV);
  try {
    const { login } = (await sdk.loginQuery({
      email: email.trim(),
      password,
    })) as any as LoginQueryQuery;
    thunkApi.dispatch(restaurantsByUserRole());
    thunkApi.dispatch(ERROR_ACTIONS.clearErrors());
    thunkApi.dispatch(createUserSession(login));
    thunkApi.dispatch(setIsAIActive(true));
    thunkApi.dispatch(resetHypothesisFrame());
    thunkApi.dispatch(resetAI());
    return login;
  } catch (error: any) {
    console.error('Failed to login', error.response.errors);
    return thunkApi.rejectWithValue(error.response.errors);
  }
});

export const initialAuthCheck = createAsyncThunk('user/initialAuthCheck', async (val: boolean, thunkApi) => {
  const authToken = getAuthToken();
  if (!authToken) {
    console.debug('No Auth Token Found');
    return thunkApi.rejectWithValue(new Error('No Auth Token Found'));
  }
  setAuthToken(authToken);
  const sdk = getGraphQLClient((thunkApi.getState() as RootState).config.NODE_ENV);
  try {
    const { authStatus } = (await sdk.authStatus()) as any as AuthStatusQuery;
    thunkApi.dispatch(restaurantsByUserRole());
    thunkApi.dispatch(fetchGroupIdMapping());
    return authStatus;
  } catch (error) {
    console.error('Failed to validate authToken', error);
    return thunkApi.rejectWithValue(error);
  }
});

export const createUserSession = createAsyncThunk(
  'user/createUserSession',
  async ({ username, email, firstName, lastName }: { username: string; email: string; firstName: string; lastName: string }, thunkAPI) => {
    const authToken = getAuthToken();
    if (!authToken) {
      throw thunkAPI.rejectWithValue('No API Token Found');
    }

    const sessionid = uuidv4();
    const request = {
      user_session_id: sessionid,
      source_module: SOURCE,
      email_id: email,
      first_name: firstName,
      last_name: lastName,
      user_name: username,
      unique_user_id: username,
      force_reload: 0,
    };
    try {
      const result = await (
        await fetch(`${getForceLogOutApiUrl((thunkAPI.getState() as RootState).config.NODE_ENV)}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
          body: JSON.stringify(request),
        })
      ).json();

      return sessionid;
    } catch (error) {
      console.error('Failed to create session', error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteUserSession = createAsyncThunk('user/deleteUserSession', async (undefined, thunkAPI) => {
  const authToken = getAuthToken();
  if (!authToken) {
    throw thunkAPI.rejectWithValue('No API Token Found');
  }

  const sessionid = getSessionID();
  try {
    const result = await (
      await fetch(`${getForceLogOutApiUrl((thunkAPI.getState() as RootState).config.NODE_ENV)}/${sessionid}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
      })
    ).json();

    return sessionid;
  } catch (error) {
    console.error('Failed to create session', error);
    return thunkAPI.rejectWithValue(error);
  }
});

export const updateUserSession = createAsyncThunk('user/updateUserSession', async (restarauntCode: string, thunkAPI) => {
  const authToken = getAuthToken();
  if (!authToken) {
    throw thunkAPI.rejectWithValue('No API Token Found');
  }

  const sessionid = getSessionID();
  const request = {
    user_session_id: sessionid,
    source_module: SOURCE,
    restaurant_code: restarauntCode,
    unique_user_id: (thunkAPI.getState() as RootState).user.userProfile?.username,
    force_reload: 0,
  };
  try {
    const result = await (
      await fetch(`${getForceLogOutApiUrl((thunkAPI.getState() as RootState).config.NODE_ENV)}/${sessionid}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
        body: JSON.stringify(request),
      })
    ).json();

    return sessionid;
  } catch (error) {
    console.error('Failed to create session', error);
    return thunkAPI.rejectWithValue(error);
  }
});

export const getUserSession = createAsyncThunk('user/getUserSession', async (restarauntCode, thunkAPI) => {
  const authToken = getAuthToken();
  if (!authToken) {
    throw thunkAPI.rejectWithValue('No API Token Found');
  }
  try {
    const result = await (
      await fetch(`${getForceLogOutApiUrl((thunkAPI.getState() as RootState).config.NODE_ENV)}?source=HITL&restaurant_code=${restarauntCode}&active=1`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
      })
    ).json();

    return result;
  } catch (error) {
    console.error('Failed to create session', error);
    return thunkAPI.rejectWithValue(error);
  }
});

export const forceLogOutUserSession = createAsyncThunk(
  'user/forceLogOutUserSession',
  async ({ restarauntCode, activeSessions }: { restarauntCode: string; activeSessions: any[] }, thunkAPI) => {
    const authToken = getAuthToken();
    if (!authToken) {
      throw thunkAPI.rejectWithValue('No API Token Found');
    }

    try {
      for (let session of activeSessions) {
        const request = {
          user_session_id: session.user_session_id,
          force_reload: 1,
          source_module: SOURCE,
          restaurant_code: restarauntCode,
          unique_user_id: session.unique_user_id,
        };

        const result = await (
          await fetch(`${getForceLogOutApiUrl((thunkAPI.getState() as RootState).config.NODE_ENV)}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: authToken,
            },
            body: JSON.stringify(request),
          })
        ).json();
        console.log(`force logout call for session id :: ${session.user_session_id}  result :: ${result}`);
      }
      return activeSessions;
    } catch (error) {
      console.error('Failed to create session', error);
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateHeartBeatSession = createAsyncThunk('user/updateHeartBeatSession', async (restarauntCode: string, thunkAPI) => {
  const authToken = getAuthToken();
  if (!authToken) {
    throw thunkAPI.rejectWithValue('No API Token Found');
  }

  const sessionid = getSessionID();
  const request = {
    user_session_id: sessionid,
    source_module: SOURCE,
    restaurant_code: restarauntCode,
    unique_user_id: (thunkAPI.getState() as RootState).user.userProfile?.username,
    active: 1,
  };
  try {
    const result = await (
      await fetch(`${getForceLogOutApiUrl((thunkAPI.getState() as RootState).config.NODE_ENV)}/${sessionid}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: authToken,
        },
        body: JSON.stringify(request),
      })
    ).json();

    return sessionid;
  } catch (error) {
    console.error('Failed to create session', error);
    return thunkAPI.rejectWithValue(error);
  }
});

export const fetchGroupIdMapping = createAsyncThunk('user/fetchGroupIdMapping', async (_, thunkAPI) => {
  const {
    config: { NODE_ENV },
  } = thunkAPI.getState() as RootState;
  const sdk = getGraphQLClient(NODE_ENV);
  try {
    const { queryCKEMenu = '{}' } =
      ((await sdk.groupIdMapping({
        // hard-coding the restaurant code as we have only one restaurant with this requirement.
        restaurantCode: 'cke_corp_reduced',
      })) as any as GroupIdMappingQuery) || {};
    inMemoryGroupIdMapping.setGroupIdMapping(queryCKEMenu ? JSON.parse(queryCKEMenu) : {});
  } catch (error: any) {
    console.error('Failed to get group id mapping', error?.response?.errors);
    return thunkAPI.rejectWithValue(error?.response?.errors);
  }
});

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    logout: (state) => {
      state.userProfile = null;
      state.isLoggedIn = false;
      state.token = null;
      setAuthToken(null);
      saveUserState(state);
    },
    authCheckSuccess: (state, action: PayloadAction<Query['authStatus']>) => {
      if (state.userProfile) {
        state.token = action.payload.authorizationToken;
        state.userProfile.authorizationToken = action.payload.authorizationToken;
        saveUserState(state);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      state.userProfile = action.payload;
      state.isLoggedIn = true;
      state.didAttemptAuthCheck = true;
      saveUserState(state);
    });
    builder.addCase(initialAuthCheck.fulfilled, (state, action) => {
      state.userProfile = action.payload;
      state.token = action.payload.authorizationToken;
      state.isLoggedIn = true;
      state.didAttemptAuthCheck = true;
      saveUserState(state);
    });
    builder.addCase(initialAuthCheck.rejected, (state, action) => {
      state.isLoggedIn = false;
      state.didAttemptAuthCheck = true;
    });
    builder.addCase(createUserSession.fulfilled, (state, action) => {
      saveSessionID(action.payload);
    });
    builder.addCase(deleteUserSession.fulfilled, (state, action) => {
      saveSessionID(null);
    });
  },
});

export const userActions = userSlice.actions;

export default userSlice.reducer;
