import { Box } from '@mui/material';
import React from 'react';
import { CartItem, CartModifierGroup } from '../../../utils/cart';
import { ParsedMenuItem, ParsedModifierGroup, sortModGroup } from '../../../utils/menu';
import ModGroup from '../menu/ModGroup';

interface IRecursiveModGroup {
  cartItem: CartItem;
  modGroup: ParsedModifierGroup;
  cartModGroup: CartModifierGroup | null;
  onChange: (modGroup: ParsedModifierGroup) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectMenuItemModifier: (menuItem: ParsedMenuItem, modGroup: ParsedModifierGroup, selected: boolean) => void;
}

export const RecursiveModGroup = (props: IRecursiveModGroup) => {
  const { modGroup, cartModGroup, cartItem } = props;

  if (!cartModGroup) return null;

  const renderedModGroupIds: Record<string, boolean> = {};
  // Singular selection, so the value is the first key in `selectedItems`
  return (
    <Box>
      {Object.values(cartModGroup.selectedItems).map((selectedModifierItem) => {
        const modGroupChildren = sortModGroup(modGroup.menuItems[selectedModifierItem.itemId]);
        const filteredModGroups = Object.values(modGroupChildren).filter((mod) => !cartItem.modifierGroups[mod.id] && !renderedModGroupIds[mod.name]);
        const key = [selectedModifierItem.id, modGroup.id, 'sub_choices'].join('_');

        if (filteredModGroups.length) {
          return (
            <Box key={key}>
              {filteredModGroups.map((modGroup) => {
                renderedModGroupIds[modGroup.name] = true;
                const modGroupProps = { ...props, modGroup, currentCartItem: selectedModifierItem };
                return <ModGroup key={modGroup.id} {...modGroupProps} />;
              })}
            </Box>
          );
        }

        return <></>;
      })}
    </Box>
  );
};
