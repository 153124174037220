import Dinero from 'dinero.js';
import { ModalityType, RestaurantSettings } from '../generated-interfaces/graphql';
import { CartItem } from './cart';
import { getMenuItemPrice, ParsedMenuItem, PRPRestaurantSettings, getMenuItemTax, getDefaultTaxRateForModality } from './menu';
import { GenericMap } from './types';

export function computeTotal(subtotal: Dinero.Dinero, taxes: Dinero.Dinero, tip: Dinero.Dinero, surcharge: Dinero.Dinero): Dinero.Dinero {
  return subtotal.add(taxes).add(tip).add(surcharge);
}

export function computeItemTotal(cartItem: CartItem, modality: ModalityType): Dinero.Dinero {
  let basePrice = Dinero({ amount: getMenuItemPrice(cartItem, modality) });
  basePrice = basePrice.add(
    Object.values(cartItem.childModifierGroups).reduce((acc, modGroup) => {
      const selectedTotal = Object.values(modGroup.selectedItems).reduce((acc, item) => {
        return acc.add(computeItemTotal(item, modality));
      }, Dinero({ amount: 0 }));
      return acc.add(selectedTotal);
    }, Dinero({ amount: 0 }))
  );
  return basePrice;
}

interface TaxRecord {
  percentage: number;
  amount: number;
}

export function computeItemTax(cartItem: CartItem, menuItems: GenericMap<ParsedMenuItem>, modality: ModalityType, restaurantSettings?: PRPRestaurantSettings): Dinero.Dinero {
  return computeCartTax([cartItem], menuItems, modality, restaurantSettings);
}

function flattenForTaxes(
  cartItems: CartItem[],
  menuItems: GenericMap<ParsedMenuItem>,
  modality: ModalityType,
  restaurantSettings?: PRPRestaurantSettings,
  cartItemsQuantity?: Record<string, number>
): TaxRecord[] {
  return cartItems.reduce((acc, item) => {
    // We removed this cart item from the available menu, we will also be removing it from the cart but race condition
    if (!menuItems[item.itemId]) {
      return acc;
    }

    const taxPercentage = getMenuItemTax(menuItems[item.itemId], modality, restaurantSettings);
    const itemPrice = getMenuItemPrice(menuItems[item.itemId], modality) * ((cartItemsQuantity || {})[item.cartItemId] || 1);
    let children = Object.values(item.childModifierGroups).flatMap((group) => {
      const modMenu = menuItems[item.itemId].modifierGroups[group.menuModifierGroupId]?.menuItems ?? [];
      return flattenForTaxes(Object.values(group.selectedItems), modMenu, modality, restaurantSettings, cartItemsQuantity);
    });

    acc.push({ percentage: taxPercentage, amount: itemPrice });
    acc = acc.concat(children);

    return acc;
  }, <TaxRecord[]>[]);
}

export function computeCartTax(
  cartItems: CartItem[],
  menuItems: GenericMap<ParsedMenuItem>,
  modality: ModalityType,
  restaurantSettings?: PRPRestaurantSettings,
  cartItemsQuantity?: Record<string, number>
): Dinero.Dinero {
  let items = flattenForTaxes(cartItems, menuItems, modality, restaurantSettings, cartItemsQuantity);
  let subtotal = Dinero({ amount: 0 });

  let groups = <TaxRecord[]>[];
  for (let item of items) {
    let match = groups.find((record) => {
      return record.percentage === item.percentage;
    });

    if (match) {
      match.amount += item.amount;
    } else {
      groups.push(item);
    }
  }

  for (let group of groups) {
    subtotal = subtotal.add(Dinero({ amount: group.amount }).allocate([group.percentage, 100 - group.percentage])[0]);
  }

  return subtotal;
}

export function computeCartSubtotal(cartItems: CartItem[], modality: ModalityType, cartItemsQuantity: Record<string, number>): Dinero.Dinero {
  let subtotal = Dinero({ amount: 0 });
  for (let item of cartItems) {
    subtotal = subtotal.add(computeItemTotal(item, modality).multiply(cartItemsQuantity[item.cartItemId] || 1));
  }
  return subtotal;
}

export function computeSurcharge(subtotal: Dinero.Dinero, surchargeType: string, surchargeValue: string): Dinero.Dinero {
  if (surchargeType === 'PERCENTAGE') {
    let surchargeAmount = Dinero({ amount: 0 });
    surchargeAmount = Dinero({ amount: subtotal.getAmount() }).allocate([parseFloat(surchargeValue), 100 - parseFloat(surchargeValue)])[0];
    return surchargeAmount;
  }
  // if surchargeType is fixed amount, convert surchargeValue to Dinero type and return directly
  return Dinero({ amount: parseFloat(surchargeValue) * 100 });
}

export function computeSurchargeTax(surcharge: Dinero.Dinero, modality: ModalityType, restaurantSettings?: PRPRestaurantSettings): Dinero.Dinero {
  let surchargeTax = Dinero({ amount: 0 });
  if (surcharge) {
    const taxRate = getDefaultTaxRateForModality(modality, restaurantSettings);
    surchargeTax = surchargeTax.add(Dinero({ amount: surcharge.getAmount() }).allocate([taxRate, 100 - taxRate])[0]);
  }
  return surchargeTax;
}

export function printCurrency(amount: Dinero.Dinero | number, currency: any): string {
  const format = '$0,0.00';
  if (typeof amount === 'number') {
    if (currency === 'CAD' || currency === 'AUD') {
      //use '$' symbol for Canada and Australia
      return Dinero({ amount: amount }).toFormat(format);
    }
    try {
      return Dinero({ amount: amount, currency: currency }).toFormat(format);
    } catch (error) {
      //if currency is invalid, will use default currency code 'USD'
      return Dinero({ amount: amount }).toFormat(format);
    }
  }
  if (typeof amount === 'undefined') {
    console.error('Undefined amount passed to printCurrency');
    return 'Undefined';
  }
  if (currency === 'CAD' || currency === 'AUD') {
    //use '$' symbol for Canada and Australia
    return amount.toFormat(format);
  }
  try {
    return Dinero({ amount: amount.getAmount(), currency: currency }).toFormat(format);
  } catch (error) {
    //if currency is invalid, will use default currency code 'USD'
    return amount.toFormat(format);
  }
}
