import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { Restaurant, UserRestaurantsRole } from '../generated-interfaces/graphql';
import { messagingActions } from '../reducers/messagingSlice';
import { getGraphQLClient } from '../utils/network';
import { getRestaurantAccessLevels, RestaurantAccess } from '../utils/restaurants';
import { fetchMenu } from './menuSlice';

export interface RestaurantState {
  selectedRestaurant: Restaurant | null;
  selectedRestaurantCode: string | null;
  selectedPrimaryRestaurantCode: string | null;
  restaurantsByUserRole: UserRestaurantsRole[];
  restaurantAccessLevels: RestaurantAccess;
  userRolesHaveLoaded: boolean;
}

export const initialState: RestaurantState = {
  selectedRestaurant: null,
  selectedRestaurantCode: null,
  selectedPrimaryRestaurantCode: null,
  restaurantsByUserRole: [],
  restaurantAccessLevels: {},
  userRolesHaveLoaded: false,
};

export const restaurantsByUserRole = createAsyncThunk('restaurant/restaurantsByUserRole', async (undefined, thunkAPI) => {
  const sdk = getGraphQLClient((thunkAPI.getState() as RootState).config.NODE_ENV);
  const { restaurantsByUserRole } = (await sdk.restaurantsByUserRoleQuery()) as any as { restaurantsByUserRole: UserRestaurantsRole[] };
  // const preSelectedRestaurant = getHighestAccessRestaurant(restaurantsByUserRole);
  // if (preSelectedRestaurant) {
  //   thunkApi.dispatch(selectRestaurant({ restaurantCode: preSelectedRestaurant.restaurantCode }));
  // }
  return restaurantsByUserRole;
});

export const selectRestaurant = createAsyncThunk(
  'restaurant/selectRestaurant',
  async ({ restaurantCode, primaryRestaurantCode }: { restaurantCode: string; primaryRestaurantCode: string }, thunkAPI) => {
    const sdk = getGraphQLClient((thunkAPI.getState() as RootState).config.NODE_ENV);
    const { restaurant } = (await sdk.restaurantInfo({ restaurantCode })) as any as { restaurant: Restaurant };
    const timezone = restaurant.hoursOfOperation?.timezone;

    thunkAPI.dispatch(fetchMenu({ restaurantCode, primaryRestaurantCode, timezone })).then((_) => {
      thunkAPI.dispatch(messagingActions.startConnecting());
    });
    return restaurant;
  }
);

export const restaurantState = createSlice({
  name: 'restaurant',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(selectRestaurant.fulfilled, (state, action) => {
      state.selectedRestaurant = action.payload;
      state.selectedRestaurantCode = action.payload.restaurantCode;
      state.selectedPrimaryRestaurantCode = action.payload.primaryRestaurantCode;
    });
    builder.addCase(restaurantsByUserRole.fulfilled, (state, action) => {
      state.restaurantsByUserRole = action.payload;
      state.restaurantAccessLevels = getRestaurantAccessLevels(action.payload);
      state.userRolesHaveLoaded = true;
    });
    builder.addCase(restaurantsByUserRole.rejected, (state, action) => {
      state.userRolesHaveLoaded = true;
    });
  },
});

export const restaurantActions = restaurantState.actions;

export default restaurantState.reducer;
