import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getErrorMessages } from '../utils/errors';
import { FORM_NAMES } from '../utils/form';
import { ErrorResponse } from '../utils/types';
import { login } from './userSlice';

interface FormFieldError {
  formField: string | string[];
  errorMessage: string;
}

interface FormError {
  errorMessage: string | string[] | null;
  formFieldErrors: Record<string, FormFieldError>;
}

export interface ErrorState {
  hasTopLevelError: boolean;
  topLevelError: string | string[] | null;
  /*
    This is the format for form errors
    addItemForm: {
        errorMessage: null,
        displayName: {
            formField: 'displayName',
            errorMessage: 'Display Name must be at least 4 characters'
        }
    }
     */
  formErrors: Record<string, FormError>;
}

const initialState: ErrorState = {
  hasTopLevelError: false,
  topLevelError: null,
  formErrors: {},
};

export const errorSlice = createSlice({
  name: 'error',
  initialState: initialState,
  reducers: {
    clearErrors: (state) => {
      state.hasTopLevelError = false;
      state.topLevelError = null;
      state.formErrors = {};
    },
    loginFailure: (state, action: PayloadAction<ErrorResponse>) => {
      state.formErrors[FORM_NAMES.loginForm] = {
        errorMessage: getErrorMessages(action.payload),
        formFieldErrors: {},
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.rejected, (state, action) => {
      state.formErrors[FORM_NAMES.loginForm] = {
        errorMessage: getErrorMessages(action.payload as ErrorResponse),
        formFieldErrors: {},
      };
    });
  },
});

export const ERROR_ACTIONS = errorSlice.actions;

export default errorSlice.reducer;
