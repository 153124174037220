import axios, { RawAxiosRequestHeaders } from 'axios';

enum HTTPMethods {
  get = 'GET',
  post = 'POST',
  put = 'PUT',
  patch = 'PATCH',
  remove = 'DELETE',
}

interface IParams {
  url: string;
  data?: any;
  headers: RawAxiosRequestHeaders;
  successCallback?: (data: any) => void;
  errorCallback?: (message: string, statusCode?: number) => void;
}

const DEFAULT_HEADERS = {
  Accept: 'application/json',
};

const apiCall = async (method: HTTPMethods, params: IParams) => {
  const { data, url, headers, successCallback, errorCallback } = params || {};

  try {
    const response = await axios({
      headers: {
        ...DEFAULT_HEADERS,
        ...headers,
      },
      method,
      url,
      ...(method !== HTTPMethods.get && { data }),
    });

    successCallback?.(response.data);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      errorCallback?.(error.message, error.status);
    } else {
      errorCallback?.('An unexpected error occurred', 500);
    }
  }
};

const apiCallWrapper = (method: HTTPMethods) => (params: IParams) => apiCall(method, params);

const get = apiCallWrapper(HTTPMethods.get);
const post = apiCallWrapper(HTTPMethods.post);
const put = apiCallWrapper(HTTPMethods.put);
const patch = apiCallWrapper(HTTPMethods.patch);
const remove = apiCallWrapper(HTTPMethods.remove);

export { get, post, put, patch, remove };
