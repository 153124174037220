import { Box, Typography } from '@mui/material';
import React from 'react';
import { useShallowSelector } from '../../app/hooks';
import { getCodeNameMapping } from '../../selectors/menu';
import { CartItem, CartModifierGroup } from '../../utils/cart';
import Colors from '../../utils/color';
import { DUMMY_MOD_SYMBOL } from '../../utils/constants';
import { getMenuItemPrice } from '../../utils/menu';
import { printCurrency } from '../../utils/money';
import CartItemModGroups from './CartItemModGroups';

interface IChildModGroup {
  modGroup: CartModifierGroup;
  quantity: number;
  cartItem: CartItem;
}

export default function ChildModGroup({ modGroup, cartItem, quantity }: IChildModGroup) {
  const codeNameMapping = useShallowSelector(getCodeNameMapping);
  const { sortOrder, selectedItems, minimumSelections, name } = modGroup;

  const modSortOrder = sortOrder.reduce((acc: Record<string, number>, sortValue) => {
    const id: number = sortValue.id || 0;
    acc[id.toString()] = sortValue.sortOrder || 0;
    return acc;
  }, {});
  const sortedSelectedItems = Object.values(selectedItems || {}).sort((a, b) => modSortOrder[a.id] - modSortOrder[b.id]);

  return (
    <div id="cartitem-child-modgroup">
      {sortedSelectedItems.length < minimumSelections ? <Typography color={Colors.Thunderbird} variant="subtitle2" children={'! ' + name} /> : null}
      {sortedSelectedItems.map((item) => {
        const menuItemPrice = getMenuItemPrice(item, item.modality, quantity);
        return (
          <div key={item.id}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="subtitle2">
                {item.modcode && codeNameMapping[item.modcode].code !== DUMMY_MOD_SYMBOL ? codeNameMapping[item.modcode].name + ' ' : ''}
                {item.name}
              </Typography>
              {menuItemPrice > 0 && (
                <Typography variant="subtitle2" sx={{ pl: 2 }}>
                  {printCurrency(menuItemPrice, 'USD')}
                </Typography>
              )}
            </Box>
            {item && item.childModifierGroups && <CartItemModGroups modGroupCartItem={item} cartItem={cartItem} quantity={quantity} />}
          </div>
        );
      })}
    </div>
  );
}
