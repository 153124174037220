enum AICommands {
  activate = '+ai',
  deactivate = '-ai',
  empty = '',
}

enum CartItemAdditionTypes {
  human = 'human',
  AI = 'ai',
}

enum EventTypes {
  start = 'start',
  audio = 'audio',
  text = 'text',
  info = 'info',
  endSession = 'end_session',
  order = 'order',
  hypothesis = 'hypothesis',
  startSession = 'start_session',
  carExit = 'car_exit',
  carEnter = 'car_enter',
  check = 'check',
  TTSRequest = 'tts_request',
  TTSOff = 'tts_off',
  TTSOn = 'tts_on',
  error = 'error',
}

enum AgentTypes {
  HITL = 'HITL',
  RULAI = 'RULAI',
  prestoAI = 'PRESTOAI',
  GPT3 = 'GPT3',
  orderBoard = 'ORDERBOARD',
  restaurantStaff = 'RESTAURANTSTAFF',
}

export { AICommands, CartItemAdditionTypes, EventTypes, AgentTypes };
