import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';
import useLogout from '../components/Login/useLogout';
import { FORCE_RELOAD_CHECK_TIME, IDLE_TIME } from '../constants';
import { updateHeartBeatSession } from '../reducers/userSlice';
import { selectNodeEnv } from '../selectors/config';
import { selectActiveRestaurantCode } from '../selectors/restaurant';
import { SOURCE } from '../utils/constants';
import { getAuthToken, getSessionID } from '../utils/local-storage';
import { getForceLogOutApiUrl } from '../utils/network';
const IDEAL_TIME_OUT_MESSAGE = 'Session Timeout, Please re login';
const USER_LOGGED_OUT_MESSAGE = 'Another user Logged in to same restaurant, You are logged out by another user';
const useApp = () => {
  const { logout } = useLogout();
  const { isLoggedIn } = useSelector((state: RootState) => state.user);
  const activeRestaurantCode = useAppSelector(selectActiveRestaurantCode);
  const nodeEnv = useAppSelector(selectNodeEnv);
  const onIdle = () => logout(IDEAL_TIME_OUT_MESSAGE);
  const { start, pause } = useIdleTimer({ timeout: IDLE_TIME, onIdle, startManually: true });
  const [timeOutInterval, setTimeOutInterval] = useState<NodeJS.Timeout | null>();
  const dispatch = useDispatch();
  const checkActiveRestaurantSession = async () => {
    try {
      const authToken = getAuthToken() + '';
      const sessionid = getSessionID();
      const result = await (
        await fetch(`${getForceLogOutApiUrl(nodeEnv)}?source_module=${SOURCE}&restaurant_code=${activeRestaurantCode}&active=1&user_session_id=${sessionid}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        })
      ).json();
      if (Array.isArray(result) && result[0]?.force_reload) logout(USER_LOGGED_OUT_MESSAGE);
      else if (activeRestaurantCode) {
        dispatch(updateHeartBeatSession(activeRestaurantCode));
      }
    } catch (error) {
      console.error('Failed to get restaurant session details', error);
    }
  };

  //Poll if there is any other active restaurant session
  useEffect(() => {
    if (isLoggedIn) start();
    else pause();
    if (timeOutInterval) clearInterval(timeOutInterval);
    if (isLoggedIn && activeRestaurantCode) {
      setTimeOutInterval(
        setInterval(() => {
          checkActiveRestaurantSession();
        }, FORCE_RELOAD_CHECK_TIME)
      );
    }
  }, [isLoggedIn, activeRestaurantCode]);
};

export default useApp;
