import React from 'react';
import { Theme, Avatar } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Colors from '../../utils/color';

const useStyles = makeStyles((theme: Theme) => ({
  messageRow: {
    display: 'inline',
    paddingRight: '4px',
  },
  messageRowRight: {
    display: 'inline',
    paddingRight: '4px',
  },
  messageRight: {
    position: 'relative',
    display: 'inline',
    color: 'gray',
    fontSize: '0.8em',
  },
  messageContent: {
    padding: 0,
    margin: 0,
    display: 'inline',
  },
  messageLeft: {
    position: 'relative',
    display: 'inline',
    color: 'red',
    fontSize: '1.1em',
    '&:hover': {
      background: 'yellow',
      cursor: 'pointer',
    },
  },
}));

interface OwnProps {
  message: string;
  displayName?: string;
  scrollRef?: React.RefObject<HTMLDivElement>;
}

export const MessageLeft = (props: OwnProps) => {
  const message = props.message ? props.message : '•';
  const displayName = props.displayName ? props.displayName : 'G';
  const classes = useStyles();
  return (
    <div className={classes.messageRow}>
      <div className={classes.messageLeft}>
        <p className={classes.messageContent} ref={props.scrollRef}>
          {message}
        </p>
      </div>
    </div>
  );
};

export const MessageRight = (props: OwnProps) => {
  const message = props.message ? props.message : '•';
  const classes = useStyles();
  return (
    <div className={classes.messageRowRight}>
      <div className={classes.messageRight}>
        <p className={classes.messageContent} ref={props.scrollRef}>
          {message}
        </p>
      </div>
    </div>
  );
};
