import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';
import { CartItemAdditionTypes } from '../constants/event';
import { CategoryAndTimePeriodQueryQuery, MenuItemsQueryQuery, ModalityType, ModifierGroupsQueryQuery } from '../generated-interfaces/graphql';
import { getModifierAndModGroupFromHypotheses } from '../utils/hypotheses';
import { ModSymbolCodeNameMappingType } from '../utils/mappings';
import {
  buildFullMenuItem,
  considerTimePeriodCategory,
  fetchMenuBasedOnStage,
  formatCategoryAndTimePeriodResponse,
  formatMenuResponse,
  formatModGroupResponse,
  MenuResponses,
  parseCategoryAndTimeperiodResponse,
  ParsedCategory,
  ParsedMenuItem,
  parseMenuResponse,
  PersistentMenuProperty,
  TopLevelMenuItem,
} from '../utils/menu';
import { GenericMap } from '../utils/types';
import { cartActions } from './cartSlice';
import { dialogActions } from './dialogSlice';
import { EntityMenuItem, ErrorTransmissionMessage, messagingActions } from './messagingSlice';

const VOICE_PROPERTIES = 'voice_properties';

export interface MenuState {
  topLevelMenuItems: GenericMap<TopLevelMenuItem>;
  parsedCategory: ParsedCategory[];
  validCategory: ParsedCategory[];
  menuRes?: MenuResponses;
  fullMenuItems: GenericMap<ParsedMenuItem>;
  persistentVoiceProps: GenericMap<PersistentMenuProperty>;
  modSymbolMapping: ModSymbolCodeNameMappingType;
  codeNameMapping: ModSymbolCodeNameMappingType;
}

export interface IAddItemToCart {
  menuItem: TopLevelMenuItem;
  quantity?: number;
  addedBy?: CartItemAdditionTypes;
  prefixWord?: string;
  inputModSymbol?: string;
}

export const initialState: MenuState = {
  topLevelMenuItems: {},
  parsedCategory: [],
  validCategory: [],
  menuRes: undefined,
  fullMenuItems: {},
  persistentVoiceProps: {},
  modSymbolMapping: {},
  codeNameMapping: {},
};

export const fetchMenu = createAsyncThunk(
  'menu/getMenu',
  async ({ restaurantCode, primaryRestaurantCode, timezone }: { restaurantCode: string; primaryRestaurantCode?: string; timezone?: string }, thunkAPI) => {
    try {
      const { categoryAndTimePeriodJSON, menuJSON, modifierGroupJSON, persistentMenuProperty } = await fetchMenuBasedOnStage({
        restaurantCode,
        primaryRestaurantCode,
        state: thunkAPI.getState() as RootState,
      });

      let persistentVoiceProps = {};
      try {
        if (persistentMenuProperty.length > 0) {
          Object.assign(
            persistentVoiceProps,
            (persistentMenuProperty as PersistentMenuProperty[]).reduce((acc, entry) => {
              acc[entry.unique_identifier] = entry;
              return acc;
            }, {} as GenericMap<PersistentMenuProperty>)
          );
        }
      } catch (error) {
        console.error('Get Persistent Menu Property Failed', error);
      }

      const processedCategoryAndTimePeriodJSON = formatCategoryAndTimePeriodResponse(categoryAndTimePeriodJSON as CategoryAndTimePeriodQueryQuery);
      const parsedCategory = parseCategoryAndTimeperiodResponse(processedCategoryAndTimePeriodJSON);
      const validCategory = timezone ? considerTimePeriodCategory(parsedCategory, timezone) : parsedCategory;

      const processedMenuJSON = formatMenuResponse(menuJSON as MenuItemsQueryQuery);
      const { topLevelMenuItems, codeNameMapping, modSymbolMapping } = parseMenuResponse(validCategory, processedMenuJSON, persistentVoiceProps);

      const modality = (thunkAPI.getState() as RootState).cart.modality;
      return {
        modality,
        topLevelMenuItems,
        processedCategoryAndTimePeriodJSON,
        processedMenuJSON,
        processedModGroupsJSON: formatModGroupResponse(modifierGroupJSON as ModifierGroupsQueryQuery),
        validCategory,
        parsedCategory,
        persistentVoiceProps,
        codeNameMapping,
        modSymbolMapping,
      };
    } catch (error) {
      console.error('Get Menu Items From Restaurant Portal Failed', error);
    }
    throw new Error(`Failed To Get Menu For Restaurant Code ${restaurantCode}`);
  }
);

export const addItemToCart = createAsyncThunk(
  'menu/addItemToCart',
  async ({ menuItem, quantity, addedBy = CartItemAdditionTypes.human, prefixWord, inputModSymbol }: IAddItemToCart, thunkAPI) => {
    const { dispatch, getState } = thunkAPI;
    const {
      cart: { modality, sequenceId: cartSequenceId },
      menu: { menuRes, persistentVoiceProps, modSymbolMapping },
    } = getState() as RootState;

    if (menuRes) {
      const parsedMenuItem = buildFullMenuItem(menuItem, menuRes, persistentVoiceProps);
      dispatch(
        cartActions.addItemToCart({
          ...parsedMenuItem,
          cartItemId: cartSequenceId,
          childModifierGroups: {},
          modality,
          modcode: menuItem.modcode,
          addedBy,
        })
      );
      if (quantity) {
        dispatch(cartActions.updateQuantity({ cartItemId: String(cartSequenceId), quantity }));
      }

      if (prefixWord) {
        // handle prefixWord by selecting it as modifier if it is found
        Object.values(parsedMenuItem.modifierGroups).every((modGroup) => {
          const target = Object.values(modGroup.menuItems).find((menuItem) => menuItem.name.toLowerCase() === prefixWord.toLowerCase());
          if (target) {
            dispatch(
              cartActions.selectModifier({
                cartItemId: cartSequenceId,
                menuItem: target,
                modGroup: modGroup,
                selected: true,
                modCode: inputModSymbol || '',
                modSymbolMapping,
              })
            );
            return false;
          }
          return true;
        });
      }

      dispatch(menuActions.setFullMenuItem(parsedMenuItem));
    }
  }
);

export const addHypothesisItemToCart = createAsyncThunk('menu/addHypothesisItemToCart', async (orderItem: EntityMenuItem, thunkAPI) => {
  const { dispatch, getState } = thunkAPI;
  const {
    menu: { topLevelMenuItems, fullMenuItems, modSymbolMapping },
    cart: { sequenceId: cartSequenceId },
  } = getState() as RootState;

  //use the first menu item with the id in the assumption of the same item will show up in the menu once
  const mainItem = Object.values(topLevelMenuItems).find((menuItem) => orderItem.id === menuItem.id);

  if (mainItem) {
    let currentCartItemId = cartSequenceId;

    //add the main item to cart
    await dispatch(
      addItemToCart({
        menuItem: mainItem,
        quantity: orderItem.quantity,
        addedBy: CartItemAdditionTypes.AI,
      })
    );
    const itemId = mainItem.id + '-' + mainItem.categoryId;
    const fullMainItem = fullMenuItems[itemId];

    const children = orderItem.children;
    if (children?.length > 0) {
      children.forEach((mod) => {
        //find the modifier group and modifier based on the modifier id
        const { modGroup, modifier } = getModifierAndModGroupFromHypotheses(fullMainItem, mod.id);
        if (modifier && modGroup) {
          //TODO handle the quantity of modifier when we have the quantity selection function in the future. Now the quantity should default to 1.
          dispatch(
            cartActions.selectModifier({
              cartItemId: currentCartItemId,
              menuItem: modifier,
              modGroup,
              selected: true,
              modCode: '',
              modSymbolMapping,
            })
          );
        } else {
          console.log("Can't find the modifier based on the id from the hypotheses in the menu", mod.id);
          const errorMessage = `can't find the modifier ${orderItem.id} based on the id from the hypothesis in the menu`;
          const payload: Partial<ErrorTransmissionMessage> = {
            data: { message: errorMessage },
          };
          dispatch(messagingActions.sendError(payload as any));
        }
      });
    }

    let quantity = orderItem.quantity ? Number(orderItem.quantity) : 1;

    if (quantity > 1) {
      dispatch(
        cartActions.updateQuantity({
          cartItemId: currentCartItemId.toString(),
          quantity: Math.min(quantity, 19),
        })
      );
    }
    dispatch(dialogActions.updateSelectedItem({ item: fullMainItem, itemCartId: currentCartItemId }));
  } else {
    console.log("Can't find the main item based on the id from the hypotheses in the menu", orderItem.id);
    const errorMessage = `can't find the main item ${orderItem.id} based on the id from the hypothesis in the menu`;
    const payload: Partial<ErrorTransmissionMessage> = {
      data: { message: errorMessage },
    };
    dispatch(messagingActions.sendError(payload as any));
  }
});

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    considerTimePeriods: (state, action: PayloadAction<{ modality: ModalityType; timezone: string }>) => {
      if (Object.keys(state.parsedCategory).length > 1 && state.menuRes) {
        const { timezone } = action.payload;
        state.validCategory = considerTimePeriodCategory(state.parsedCategory, timezone);
        const { menuItems, overrides, menuItemSettings, posSettings } = state.menuRes;
        const { topLevelMenuItems, codeNameMapping, modSymbolMapping } = parseMenuResponse(
          state.validCategory,
          { menuItems, overrides, menuItemSettings, posSettings },
          state.persistentVoiceProps
        );
        state.topLevelMenuItems = topLevelMenuItems;
        state.codeNameMapping = codeNameMapping;
        state.modSymbolMapping = modSymbolMapping;
      }
    },
    setFullMenuItem: (state, action: PayloadAction<ParsedMenuItem>) => {
      const item = action.payload;
      const itemId = item.itemId;
      state.fullMenuItems[itemId] = item;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMenu.fulfilled, (state, action) => {
      state.validCategory = action.payload.validCategory;
      state.topLevelMenuItems = action.payload.topLevelMenuItems;
      state.modSymbolMapping = action.payload.modSymbolMapping;
      state.codeNameMapping = action.payload.codeNameMapping;
      state.menuRes = {
        ...action.payload.processedCategoryAndTimePeriodJSON,
        ...action.payload.processedMenuJSON,
        ...action.payload.processedModGroupsJSON,
      };
      state.persistentVoiceProps = action.payload.persistentVoiceProps;
      state.parsedCategory = action.payload.parsedCategory;
    });
  },
});

export const menuActions = menuSlice.actions;

export default menuSlice.reducer;
