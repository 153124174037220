import { FormControl, FormGroup, FormLabel, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { CartItem, CartModifierGroup } from '../../../utils/cart';
import { isItem86edToday, ParsedMenuItem, ParsedModifierGroup } from '../../../utils/menu';
import { MultipleModChoice } from '../components/ModChoice';
import { ModGroupLabel } from '../components/ModGroupLabel';
import { RecursiveModGroup } from '../components/RecursiveModGroup';
import { sortAndFilterMenuItems } from '../util/helper';

const useStyles = makeStyles(({ spacing }: Theme) => ({
  modGroup: {
    margin: `${spacing(1)} 0px 0px 0px !important`,
    '& legend': {
      width: '100%',
    },
    padding: `0px ${spacing(1)}`,
    width: '100%',
  },
  choiceContainer: {
    marginTop: spacing(1.625),
  },
}));

interface IModGroup {
  cartItem: CartItem;
  modGroup: ParsedModifierGroup;
  cartModGroup: CartModifierGroup | null;
  onChange: (modGroup: ParsedModifierGroup) => (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectMenuItemModifier: (menuItem: ParsedMenuItem, modGroup: ParsedModifierGroup, selected: boolean) => void;
}

export default function MultipleSelectionModGroup(props: IModGroup) {
  const classes = useStyles({});
  const { modGroup, onChange, cartModGroup, cartItem } = props;

  //sort and filter the modifiers before rendering
  const menuItems = sortAndFilterMenuItems(modGroup.menuItems);

  let selectedModNumber = 0;
  if (cartModGroup) {
    const selectedMod = cartModGroup.selectedItems;
    selectedModNumber = Object.values(selectedMod).length;
  }
  const key = [cartItem.id, modGroup.id].join('_');

  return (
    <React.Fragment key={key}>
      <FormControl component="fieldset" className={classes.modGroup}>
        <FormLabel component="legend">
          <ModGroupLabel modGroup={modGroup} selectedModNumber={selectedModNumber} />
        </FormLabel>
        <FormGroup row className={classes.choiceContainer}>
          {menuItems.map((child) => {
            if (child.available || isItem86edToday(child)) {
              return (
                <MultipleModChoice
                  key={key + child.id}
                  childModifierGroup={cartItem.childModifierGroups[modGroup.id]}
                  menuItem={child}
                  cartModGroup={cartModGroup}
                  onChange={onChange(modGroup)}
                />
              );
            }
            return null;
          })}
        </FormGroup>
      </FormControl>
      <RecursiveModGroup {...props} />
    </React.Fragment>
  );
}
