import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { useSelector } from 'react-redux';
import { SetCartItemRef } from '../../App';
import { CartItemDetail } from '../../components/ItemBuilder/menu/CartItemDetail';
import { selectCartItems } from '../../selectors/cart';
import { selectSelectedItemCartId } from '../../selectors/dialog';
import Colors from '../../utils/color';

const useStyles = makeStyles((theme: Theme) => ({
  conatiner: {
    padding: '10px',
    height: (props: OwnProps) => (props.isAIActive ? 'calc(100% - 144px)' : 'calc(100% - 96px)'),
    overflowY: 'auto',
    overflowX: 'hidden',
    border: `${theme.spacing(0.125)} solid ${Colors.alto}`,
  },
  title: {
    fontStyle: 'italic',
  },
}));

interface OwnProps {
  setCartItemRef: SetCartItemRef;
  isAIActive: boolean;
}

export default function ItemBuilder(props: OwnProps) {
  const { setCartItemRef } = props;
  const classes = useStyles(props);
  const cartItems = useSelector(selectCartItems);
  const selectedItemCartId = useSelector(selectSelectedItemCartId);

  console.log('selectedItemCartId::', selectedItemCartId);
  console.log('cartItems::', cartItems);

  return (
    <div className={classes.conatiner}>
      {cartItems && selectedItemCartId && cartItems[selectedItemCartId] ? <CartItemDetail cartItem={cartItems[selectedItemCartId]} setCartItemRef={setCartItemRef} /> : undefined}
    </div>
  );
}
