import { Grid, Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector, useShallowSelector } from '../src/app/hooks';
import AISuggestionsBar from './components/AISuggestionsBar';
import Header from './components/Layout/Header';
import RightBar from './components/Layout/RightBar';
import { OmniBar } from './components/OmniBar/OmniBar';
import ItemBuilder from './features/item-builder/ItemBuilder';
import { Messaging } from './features/messaging/Messaging';
import useAIMessaging from './features/messaging/useAIMessaging.hooks';
import OrderReview from './features/order-review/OrderReview';
import useApp from './hooks/useApp.hooks';
import { menuActions } from './reducers/menuSlice';
import { initialAuthCheck } from './reducers/userSlice';
import { selectTimezon } from './selectors/restaurant';
import Colors from './utils/color';
import useNewRelicScript from './hooks/useNewRelic.hooks';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    height: 'calc(100vh - 64px)',
    backgroundColor: Colors.porcelain,
  },
  orderReview: {
    fontStyle: 'italic',
  },
  middleSection: {
    flex: 1,
    width: '50%',
  },
  transcriptContainer: {
    display: 'flex',
    maxHeight: '100%',
    minWidth: '300px',
    maxWidth: '360px',
  },
}));

export interface CartItemRefs {
  [cartItemId: number]: HTMLDivElement | null;
}
export type SetCartItemRef = (cartItemId: number, ref: HTMLDivElement | null) => void;

function App() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const modality = useAppSelector((state) => state.cart.modality);
  const { isLoggedIn, didAttemptAuthCheck } = useShallowSelector((state) => state.user);
  const timezone = useAppSelector(selectTimezon);

  const cartItemRefs = useRef<CartItemRefs>({});
  useApp();
  useNewRelicScript();
  const setCartItemRef: SetCartItemRef = (cartItemId: number, ref: HTMLDivElement | null) => {
    cartItemRefs.current[cartItemId] = ref;
  };

  useEffect(() => {
    if (timezone) {
      const timer = setInterval(() => {
        dispatch(menuActions.considerTimePeriods({ modality, timezone }));
      }, 30 * 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [timezone]);

  useEffect(() => {
    dispatch(initialAuthCheck(true));
  }, []); // Run only once

  const RequireAuth = ({ children }: { children: JSX.Element }) => {
    if (!didAttemptAuthCheck) {
      return <></>;
    }
    if (!isLoggedIn) {
      return <Navigate to="/login" replace />;
    }
    return children;
  };

  const { isAIActive } = useAIMessaging();

  return (
    <RequireAuth>
      <div className="App">
        <Header />
        <div className={classes.container}>
          <Grid item className={classes.transcriptContainer} direction="column" container>
            <Messaging />
          </Grid>

          <Grid item className={classes.middleSection}>
            <ItemBuilder setCartItemRef={setCartItemRef} isAIActive={isAIActive} />
            {isAIActive && <AISuggestionsBar />}
            <OmniBar />
          </Grid>

          <RightBar>
            <OrderReview />
          </RightBar>
        </div>
      </div>
    </RequireAuth>
  );
}

export default App;
