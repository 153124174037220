import { useAlert } from 'react-alert';
import { useDispatch } from 'react-redux';
import { deleteUserSession, userActions } from '../../reducers/userSlice';
const DELAY = 2000;
const useLogout = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const clearSession = () => {
    dispatch(deleteUserSession());
    dispatch(userActions.logout());
  };
  const logout = (message?: string) => {
    if (message) {
      alert.info(message, { timeout: DELAY, onClose: clearSession });
    } else {
      clearSession();
    }
  };

  return {
    logout,
  };
};

export default useLogout;
