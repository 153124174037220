import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Theme, Button } from '@mui/material';
import Colors from '../../utils/color';
import { INTENT_CARDS, VISIBILITY_FACTOR, IntentCard } from '../../utils/constants';
import { useSelector } from 'react-redux';
import { activeCartSelector } from '../../utils/cart';
import { restaurantInfoSelector } from '../../utils/restaurants';
import { useAppDispatch, useAppSelector, useShallowSelector } from '../../app/hooks';
import { dialogActions } from '../../reducers/dialogSlice';
import { orderActions } from '../../reducers/orderSlice';
import { getMessageWithTotal } from '../../utils/getTTSMessage';
import { selectOrderValues } from '../../selectors/order';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: Colors.daintree,
    padding: '10px',
  },
  card: {
    color: Colors.daintree,
    height: '48px',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold !important',
    fontSize: '12px !important',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    lineHeight: `${theme.spacing(2.25)} !important`,
  },
}));

interface OwnProps {
  sendMessage: (message: string, metadata?: any) => void;
  sendMetric: (message: string) => void;
}

export default function IntentsSection(props: OwnProps) {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const cartActive = useSelector(activeCartSelector);
  const currentTransactionId = useAppSelector((state) => state.order.currentTransactionId);
  const transactions = useAppSelector((state) => state.order.transactions);
  const ITEM_BY_ITEM = useAppSelector((state) => state.config.ITEM_BY_ITEM);
  const completeClickCount = useAppSelector((state) => state.order.completeClickCount);
  const { total } = useShallowSelector(selectOrderValues);

  const selectedRestaurant = useSelector(restaurantInfoSelector);
  const [downTime, setDownTime] = useState(0);
  const numOfIntents = INTENT_CARDS.length;
  const intentsStatus = useAppSelector((state) => state.dialog.intentsStatus);
  const DISABLE_INTENT_INTERVAL = useAppSelector((state) => state.config.DISABLE_INTENT_INTERVAL);

  useEffect(() => {
    dispatch(dialogActions.initIntentsStatus(new Array(numOfIntents).fill(true)));
  }, []);

  const onIntentDown = () => {
    setDownTime(new Date().valueOf());
  };

  const onIntentUp = (intent: IntentCard, idx: number) => {
    const timePressed = Math.floor((new Date().valueOf() - downTime) / 100);
    setDownTime(0);
    onIntentsClick(intent.title, intent.messages, timePressed, intent.intent, idx);
  };

  const setIntentsStatus = (idx: number, status: boolean) => {
    dispatch(dialogActions.setIntentsStatus({ idx, status }));
  };

  const onIntentsClick = (title: string, messages: string[], cTimer: number, intent: string, idx: number) => {
    if (title === 'Read Back') {
      props.sendMessage('', { intent, timeDelta: cTimer });
      return;
    }

    if (title === 'Complete') {
      dispatch(orderActions.increaseCompleteClickCount());
    }

    props.sendMetric(title + ' intent clicked');

    let message = messages[cTimer] || messages[messages.length - 1];

    if (['Total', 'Complete'].includes(title)) {
      message = getMessageWithTotal({ message, ITEM_BY_ITEM, currentTransactionId, transactions, completeClickCount, total, title });
    }
    message = message.replaceAll('{RESTAURANT_NAME}', selectedRestaurant?.restaurantName || '');
    props.sendMessage(message, { intent, timeDelta: cTimer });

    setIntentsStatus(idx, false);
    setTimeout(() => {
      setIntentsStatus(idx, true);
    }, DISABLE_INTENT_INTERVAL);
  };

  const intents = INTENT_CARDS.filter((intent) => {
    if (intent.visibility !== undefined) {
      switch (intent.visibility) {
        case VISIBILITY_FACTOR.CART_ACTIVE:
          return cartActive;
        case VISIBILITY_FACTOR.CART_EMPTY:
          return !cartActive;
        default:
          return true;
      }
    } else {
      return true;
    }
  });

  return (
    <div className={classes.container}>
      <Grid container spacing={1}>
        {intents.map((intentCard, idx) => (
          <Grid key={idx} item xs={intentCard.cardSize}>
            <Button
              key={`intent-${idx}`}
              onMouseDown={() => onIntentDown()}
              onMouseUp={() => onIntentUp(intentCard, idx)}
              className={classes.card}
              style={{ backgroundColor: !intentsStatus[idx] ? Colors.alto2 : intentCard.color, color: intentCard.textColor }}
              disabled={!intentsStatus[idx]}
            >
              {intentCard.title}
            </Button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
