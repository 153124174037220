import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import MemoryIcon from '@mui/icons-material/Memory';
import MicIcon from '@mui/icons-material/Mic';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import { AppBar, Theme, Toolbar, Typography } from '@mui/material';
import { green, grey, red, yellow } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector, useShallowSelector } from '../../app/hooks';
import useAIMessaging from '../../features/messaging/useAIMessaging.hooks';
import { messagingActions } from '../../reducers/messagingSlice';
import { Player } from '../Audio/Player';
import { Logout } from '../Login/Logout';
import { RestaurantPicker } from '../Restaurant/RestaurantPicker';
import { VisualIndicator } from '../VisualIndicator';
import { Timer } from '../VisualIndicator/Timer';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    paddingLeft: theme.spacing(2),
    flexGrow: 1,
  },
  restaurantPicker: {
    marginLeft: theme.spacing(2),
  },
  logout: {
    marginLeft: theme.spacing(2),
  },
  orderStatusClass: {
    color: 'grey',
  },
}));

export default function Header() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isCarPresent = useAppSelector((state) => state.messages.isCarPresent);
  const isNewSession = useAppSelector((state) => state.messages.isNewSession);
  const isConnected = useAppSelector((state) => state.messages.isConnected);
  const isStaffIntervention = useAppSelector((state) => state.messages.isStaffIntervention);
  const [orderStatus, setOrderStatus] = useState('NO_ORDER');
  const sendStaffInterventionMetric = () => {
    //TODO
  };
  const isTTSOn = useAppSelector((state) => state.messages.isTTSOn);
  const { currentTransactionId, transactions, sendOrderFailed } = useShallowSelector((state) => state.order);
  const transaction = currentTransactionId ? transactions[currentTransactionId] : null;

  useEffect(() => {
    if (sendOrderFailed) {
      setOrderStatus('FAILED');
    } else if (!transaction) {
      setOrderStatus('NO_ORDER');
    } else {
      if (transaction?.data?.isFinal && transaction.status === 'COMPLETED') {
        setOrderStatus('COMPLETED');
        setTimeout(() => {
          setOrderStatus('NO_ORDER');
        }, 3000);
      } else if (transaction.status === 'PENDING' || transaction.status === 'COMPLETED') {
        setOrderStatus('PENDING');
      } else if (transaction.status === 'FAILED') {
        setOrderStatus('FAILED');
      } else {
        setOrderStatus('NO_ORDER');
      }
    }
  }, [transaction, sendOrderFailed]);

  const getOrderStatusColor = (status: string) => {
    switch (status) {
      case 'NO_ORDER':
        return grey['500'];
      case 'COMPLETED':
        return green['A400'];
      case 'PENDING':
        return yellow['500'];
      case 'FAILED':
        return red['700'];
      default:
        return '';
    }
  };

  useEffect(() => {
    dispatch(messagingActions.sendTTSRequest({} as any));
  }, [isConnected, dispatch]);

  const { isAIActive, toggleAIIndicator } = useAIMessaging();

  return (
    <AppBar position="static">
      <Toolbar>
        <img src="/presto_logo.svg" alt="Presto Logo" />
        <Typography className={classes.title}>Voice Terminal v1.0.0</Typography>
        <VisualIndicator isActive={isAIActive} onClick={toggleAIIndicator} IconComponent={MemoryIcon} />
        <Player />
        <VisualIndicator IconComponent={MicIcon} isActive={isTTSOn} />
        <VisualIndicator IconComponent={NetworkCheckIcon} isActive={isConnected} />
        <VisualIndicator IconComponent={HeadsetMicIcon} isActive={isStaffIntervention} onClick={sendStaffInterventionMetric} />
        <VisualIndicator IconComponent={DirectionsCarIcon} isActive={isCarPresent || isNewSession} />
        <VisualIndicator IconComponent={AttachMoneyIcon} customizedColor={getOrderStatusColor(orderStatus)} />
        <Timer />
        <div className={classes.restaurantPicker}>
          <RestaurantPicker />
        </div>
        <div className={classes.logout}>
          <Logout />
        </div>
      </Toolbar>
    </AppBar>
  );
}
