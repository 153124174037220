import { Grid, Theme, Typography } from '@mui/material';
import { Mail, Message } from '@mui/icons-material';
import * as React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Dispatch } from 'redux';
import { ErrorMessage } from '../../components/ErrorMessage';
import { LoginForm, LoginFormData } from '../../components/Login/LoginForm';
import { Image } from '../../components/Layout/Image';
import prestoLogo from '../../images/presto_logo_white.png';
import { RootState, AppDispatch } from '../../app/store';
import { login } from '../../reducers/userSlice';
import { FORM_NAMES } from '../../utils/form';
import { withStyles, createStyles, WithStyles } from '@mui/styles';
import { Navigate } from 'react-router-dom';

const styles = (theme: Theme) =>
  createStyles({
    background: {
      width: '100vw',
      height: '100vh',
      backgroundColor: theme.palette.primary.main,
      position: 'absolute',
      zIndex: -999,
    },
    prestoLogo: {
      marginBottom: theme.spacing(4),
      '@media (max-height: 600px)': {
        marginBottom: 0,
      },
    },
    gridContainer: {
      height: '100vh',
      backgroundColor: theme.palette.primary.main,
      minHeight: '480px',
      paddingBottom: '100px',
      '@media (max-height: 600px)': {
        paddingBottom: 0,
      },
    },
    mainContainer: {
      flex: 1,
    },
    loginForm: {
      margin: theme.spacing(2),
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(2),
      },
    },
    loginFormInputs: {
      color: '#fff !important',
      borderBottom: '2px solid white',
      backgroundColor: '#18394e !important',
    },
    loginFormLabel: {
      color: '#fff !important',
      '&.Mui-focused': {
        color: '#fff !important',
      },
      '& .MuiInputLabel-asterisk': {
        display: 'none',
      },
    },
    contactContainer: {
      marginTop: theme.spacing(2),
    },
    contact: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 0),
      '& a': {
        textDecoration: 'none',
        color: '#2f9bf3',
      },
    },
  });

class Login extends React.Component<StateProps> {
  private passwordRefInput: React.RefObject<HTMLInputElement>;
  constructor(props: StateProps) {
    super(props);
    this.passwordRefInput = React.createRef();
  }
  public render() {
    const { classes, login, formErrors, userProfile } = this.props;
    const onSubmit = (formData: LoginFormData) => {
      login(formData);
      this.passwordRefInput.current?.blur();
    };
    return (
      <React.Fragment>
        <div className={classes.background} />
        <Grid className={classes.gridContainer} container direction="column" alignItems="center">
          <Grid container className={classes.mainContainer} direction="column" alignItems="center" justifyContent="space-around">
            <Grid item>
              <Image className={classes.prestoLogo} url={prestoLogo} alt="Presto Logo" />
            </Grid>
            <Grid item>
              {userProfile && <Navigate to="/" replace={true} />}
              <div className={classes.loginForm}>
                {formErrors && formErrors.errorMessage ? <ErrorMessage message={'Invalid Username or Password'} /> : null}
                <LoginForm
                  onSubmit={onSubmit}
                  inputClassName={classes.loginFormInputs}
                  inputLabelClassName={classes.loginFormLabel}
                  formErrors={formErrors}
                  passwordInputRef={this.passwordRefInput}
                />
              </div>
            </Grid>
            <Grid item />
          </Grid>
          <Grid item className={classes.contactContainer}>
            <Typography className={classes.contact}>
              <Mail color="secondary" style={{ marginRight: '5px' }} />
              <a href="mailto:contactless.support@presto.com">contactless.support@presto.com</a>
            </Typography>
            <Typography className={classes.contact}>
              <Message color="secondary" style={{ marginRight: '5px' }} />
              <a href="tel: +14155489347">+1 (415) 548 - 9347</a>
            </Typography>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    formErrors: state.error.formErrors[FORM_NAMES.loginForm],
    userProfile: state.user.userProfile,
  };
};

const mapDispatchToProps = (dispatch: AppDispatch) => {
  return {
    login: (credentials: { email: string; password: string }) => dispatch(login(credentials)),
  };
};

type StateProps = ConnectedProps<typeof connected> & WithStyles<typeof styles>;

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(withStyles(styles)(Login));
